import { useState, useEffect } from "react";
// import "css/loading.css";

/* -------------------------------------------------------- ||
||															||
||						PAGINA DE CARGA						||
||															||
|| -------------------------------------------------------- */

const Loading = () => {
	// Estado para manejar el mensaje
	const [message, setMessage] = useState("Cargando...");

	// Cambiar el mensaje después de 20 segundos
	useEffect(() => {
		const timer = setTimeout(() => {
			setMessage("Por favor, refresque la página.");
		}, 20000); // Cambiar después de 20000ms (20 segundos)

		// Limpiar el temporizador en caso de desmontar el componente
		return () => clearTimeout(timer);
	}, []);

	return <main className="loagind-page">{message}</main>;
};

export default Loading;
